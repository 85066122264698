import React from "react";
import {
  IBlockContent,
  IBlockContentScript,
  IAttachment
} from "../../../../../../../interfaces";

import classes from "./BlockContentScriptShow.module.scss";

interface IProps {
  blockContent: IBlockContent;
  showDummyContent?: boolean;
  showOnlyInTemplateEditor?: boolean;
}

const BlockContentScriptShow: React.FC<IProps> = props => {
  const blockContent = props.blockContent as IBlockContentScript;

  try {
    const styleSheet = document.styleSheets[0];

      for (let i = 0; i < styleSheet.cssRules.length; i++) {
        let rule:any = styleSheet.cssRules[i];

        if (rule.selectorText === ".dynamic-script-bg") {
          styleSheet.deleteRule(i);
        }
        if (rule.selectorText === ".dynamic-script-titles") {
          styleSheet.deleteRule(i);
        }

        if (rule.selectorText === "#offer-preview h1") { styleSheet.deleteRule(i); }
        if (rule.selectorText === "#offer-preview h2") { styleSheet.deleteRule(i); }
        if (rule.selectorText === "#offer-preview h3") { styleSheet.deleteRule(i); }
        if (rule.selectorText === "#offer-preview h4") { styleSheet.deleteRule(i); }
        if (rule.selectorText === "#offer-preview h5") { styleSheet.deleteRule(i); }                                

      }

    styleSheet.insertRule(".dynamic-script-bg { background-color:"+blockContent.backgroundcolor+" !important;}");
    styleSheet.insertRule(".dynamic-script-titles { color:"+blockContent.titlecolor+" !important;}");
    styleSheet.insertRule("#offer-preview h1 { color:"+blockContent.titlecolor+" !important; }");
    styleSheet.insertRule("#offer-preview h2 { color:"+blockContent.titlecolor+" !important; }");
    styleSheet.insertRule("#offer-preview h3 { color:"+blockContent.titlecolor+" !important; }");
    styleSheet.insertRule("#offer-preview h4 { color:"+blockContent.titlecolor+" !important; }");
    styleSheet.insertRule("#offer-preview h5 { color:"+blockContent.titlecolor+" !important; }");

  } catch {

    let styleSheet:any = document.createElement('style');
    document.head.appendChild(styleSheet);
    styleSheet = styleSheet.sheet;
    styleSheet.insertRule(".dynamic-script-bg { background-color:"+blockContent.backgroundcolor+" !important;}");
    styleSheet.insertRule(".dynamic-script-titles { color:"+blockContent.titlecolor+" !important;}");
    styleSheet.insertRule("#offer-preview h1 { color:"+blockContent.titlecolor+" !important; }");
    styleSheet.insertRule("#offer-preview h2 { color:"+blockContent.titlecolor+" !important; }");
    styleSheet.insertRule("#offer-preview h3 { color:"+blockContent.titlecolor+" !important; }");
    styleSheet.insertRule("#offer-preview h4 { color:"+blockContent.titlecolor+" !important; }");
    styleSheet.insertRule("#offer-preview h5 { color:"+blockContent.titlecolor+" !important; }");

    //styleSheet.insertRule(".dynamic-script { background-color:"+blockContent.backgroundcolor+" !important; color:"+blockContent.titlecolor+" !important;}");
    styleSheet.insertRule("#offer-preview::before, .page-content::after {border: 0.5cm solid "+blockContent.backgroundcolor+";}");
    //styleSheet.insertRule("#offer-preview::before, .page-content::after {border: 0.5cm solid #ff0000;}");
    styleSheet.insertRule("#offer-preview {padding:0;}");
  }

  return null;
  /*
  if(!props.showOnlyInTemplateEditor) return null;
  return (<span>
    {props.showOnlyInTemplateEditor && <div style={{textAlign:"center", //background: "repeating-linear-gradient(to right, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0.5) 70px, rgba(255, 255, 255, 0) 70px, rgba(255, 255, 255, 0) 120px)"}}>
      background: "repeating-linear-gradient(to right, rgba(0, 0, 0, 0.5) 0px, rgba(0, 0, 0, 0.5) 10px, rgba(255, 255, 255, 0) 10px, rgba(255, 255, 255, 0) 20px)",
      backgroundSize: "100% 9%",
      backgroundRepeat: "no-repeat",
      backgroundPositionY: "50%"}}>
    <span style={{background:"#ffffff"}}>S C R I P T</span></div>}

  </span>);
  */
};

export default BlockContentScriptShow;
