import React from "react";
import { EContentType } from "../../../../../enums";
import { IBlockContent, IOffer } from "../../../../../interfaces";
import BlockContentContentShow from "./BlockContent/BlockContentContent/BlockContentContentShow";

import BlockContentDealBetweenShow from "./BlockContent/BlockContentDealBetween/BlockContentDealBetweenShow";
import BlockContentPartiesShow from "./BlockContent/BlockContentParties/BlockContentPartiesShow";

import BlockContentImageShow from "./BlockContent/BlockContentImage/BlockContentImageShow";
import BlockContentPricingTableShow from "./BlockContent/BlockContentPricingTable/BlockContentPricingTableShow";
import BlockContentVisualShow from "./BlockContent/BlockContentVisual/BlockContentVisualShow";
import BlockContentTeamMemberShow from "./BlockContent/BlockContentTeamMember/BlockContentTeamMemberShow";
import BlockContentLegalBoxShow from "./BlockContent/BlockContentLegalBox/BlockContentLegalBoxShow";
import BlockContentAttachmentShow from "./BlockContent/BlockContentAttachment/BlockContentAttachmentShow";
import BlockContentSignatureShow from "./BlockContent/BlockContentSignature/BlockContentSignatureShow";

import BlockContentFieldsShow from "./BlockContent/BlockContentFields/BlockContentFieldsShow";
import BlockContentProductsShow from "./BlockContent/BlockContentProducts/BlockContentProductsShow";
import BlockContentProducts2Show from "./BlockContent/BlockContentProducts2/BlockContentProducts2Show";
import BlockContentProducts3Show from "./BlockContent/Private/BlockContentProducts3/BlockContentProducts3Show";

import BlockContentSignaturesShow from "./BlockContent/BlockContentSignatures/BlockContentSignaturesShow";

import BlockContentHTMLShow from "./BlockContent/BlockContentHTML/BlockContentHTMLShow";
import BlockContentTAGShow from "./BlockContent/BlockContentTAG/BlockContentTAGShow";

import BlockContentScriptShow from "./BlockContent/BlockContentScript/BlockContentScriptShow";

interface IProps {
  blockContent: IBlockContent;
	showDummyContent?: boolean;
  showOnlyInTemplateEditor? : boolean;

  onChange?: (blockContent: IBlockContent) => void;
	onBlur?: (blockContent: IBlockContent) => void;

	offer?: IOffer;
}

const BlockContentShow: React.FC<IProps> = ({ blockContent, showDummyContent, showOnlyInTemplateEditor, onChange, onBlur, offer }) => {

	switch (blockContent.contentType) {
    case EContentType.CONTENT:
      return <BlockContentContentShow blockContent={blockContent} showDummyContent={showDummyContent} />;
    case EContentType.IMAGE:
      return <BlockContentImageShow blockContent={blockContent} showDummyContent={showDummyContent} />;

    case EContentType.DEAL_BETWEEN:
      return <BlockContentDealBetweenShow showDummyContent={showDummyContent} />;
    case EContentType.PARTIES:
      return <BlockContentPartiesShow blockContent={blockContent} showDummyContent={showDummyContent} />;


    case EContentType.PRICING:
      return <BlockContentPricingTableShow blockContent={blockContent} showDummyContent={showDummyContent} />;
    case EContentType.TEAM_MEMBER:
      return <BlockContentTeamMemberShow blockContent={blockContent} showDummyContent={showDummyContent} />;
    case EContentType.VISUAL:
      return <BlockContentVisualShow blockContent={blockContent} showDummyContent={showDummyContent} offer={offer}/>;
    case EContentType.LEGAL_BOX:
      return <BlockContentLegalBoxShow showDummyContent={showDummyContent} />;
    case EContentType.ATTACHMENT:
      return <BlockContentAttachmentShow blockContent={blockContent} showDummyContent={showDummyContent} />;
    case EContentType.SIGNATURE:
      return <BlockContentSignatureShow blockContent={blockContent} showDummyContent={showDummyContent} />;

    case EContentType.FIELDS:
      return <BlockContentFieldsShow blockContent={blockContent} showDummyContent={showDummyContent} />;
      
    case EContentType.PRODUCTS:
 	    return <BlockContentProductsShow blockContent={blockContent} showDummyContent={showDummyContent} onChange={onChange} onBlur={onBlur} offer={offer} />;

    case EContentType.PRIVATE:
      return <BlockContentProducts2Show blockContent={blockContent} showDummyContent={showDummyContent} onChange={onChange} onBlur={onBlur} offer={offer} />;
    case EContentType.PRIVATE2:
      return <BlockContentProducts3Show blockContent={blockContent} showDummyContent={showDummyContent} onChange={onChange} onBlur={onBlur} offer={offer} />;

    case EContentType.SIGNATURES:
      return <BlockContentSignaturesShow blockContent={blockContent} showDummyContent={showDummyContent} />;

    case EContentType.HTML:
      return <BlockContentHTMLShow blockContent={blockContent} showDummyContent={showDummyContent} />;

    case EContentType.TAG:
      return <BlockContentTAGShow blockContent={blockContent} showDummyContent={showDummyContent} showOnlyInTemplateEditor={showOnlyInTemplateEditor} />;

    case EContentType.SCRIPT:
      return <BlockContentScriptShow blockContent={blockContent} showDummyContent={showDummyContent} showOnlyInTemplateEditor={showOnlyInTemplateEditor} />;

    default:
      return null;
  }
};

export default BlockContentShow;
