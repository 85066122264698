import 'react-datepicker/dist/react-datepicker.css';
import 'react-intl-tel-input/dist/main.css';

import FontPicker from 'font-picker-react';
import React from 'react';
import ReactDatePicker from 'react-datepicker';
import IntlTelInput from 'react-intl-tel-input';
import Textarea from 'react-textarea-autosize';
import { Button, FormGroup, CustomInput, Input, Label } from 'reactstrap';

import { IAttachment } from '../../../interfaces';
import CustomDropzone from '../../CustomDropzone/CustomDropzone';
import ColorPicker from '../ColorPicker/ColorPicker';
import CustomSelect from '../CustomSelect/CustomSelect';
import CustomTinyMceEditor from '../CustomTinyMceEditor/CustomTinyMceEditor';
//import CustomTinyMceEditor from '../CustomJoditEditor/CustomJoditEditor';

// import "react-quill/dist/quill.snow.css";
import classes from "./Input.module.scss";

//const rndstr = () => (Math.random().toString(36).substring(7));

const input = ({ formElement, changed, onBlur, hideLabel }: any) => {
  let inputElement = null;
  switch (formElement.config.elementType) {
    case "login":
    case "input":
      if (formElement.config.elementConfig.type === "tel") {
        inputElement = (
          <IntlTelInput
            autoComplete="new-password"

            style={{ width: "100%" }}
            preferredCountries={["fi"]}
            containerClassName={`intl-tel-input ${classes.intlTel}`}
            inputClassName={`${classes.Input} ${classes.tel}`}
            type={formElement.config.elementConfig.type}
            fieldId={formElement.id}
            placeholder={
              formElement.config.elementConfig.placeholder ||
              formElement.config.elementConfig.label
            }
            value={formElement.config.value}
            onPhoneNumberChange={(
              valid: boolean,
              value: string,
              country: string
            ) => changed(valid, value, country)}
            onPhoneNumberBlur={onBlur}
            invalid={!formElement.config.valid && formElement.config.touched}
            separateDialCode={true}
          />
        );
			} else if (formElement.config.elementConfig.type === "custom") {
        inputElement = formElement.config.custom({
					formElement,
					className:classes.Input,
					onBlur:onBlur,
				})
			} else {
				// what is the point???
				//readOnly={formElement.config.elementConfig.type === "password"}
        //onFocus={formElement.config.elementConfig.type === "password" ? (event) => event.target.removeAttribute('readonly') : null}
      
        //		id={formElement.id}

        inputElement = (
          <Input
            autoComplete={formElement.config.elementType==="input"?"new-password":null}
            style={formElement.config.elementConfig.style}
            className={classes.Input}
            type={formElement.config.elementConfig.type}
            placeholder={
              formElement.config.elementConfig.placeholder ||
              formElement.config.elementConfig.label
            }
            value={formElement.config.value}
            checked={formElement.config.value}

            onChange={event => changed(event, formElement.id)}
            invalid={!formElement.config.valid && formElement.config.touched}
            disabled={formElement.config.disabled}
            onBlur={onBlur}
          />
        );
      }
      break;
    case "textarea":
      let classNames = ["form-control", classes.Input];
      if (!formElement.config.valid && formElement.config.touched) {
        classNames.push("is-invalid");
      }

      inputElement = (
        <Textarea
          id={formElement.id}
          className={classNames.join(" ")}
          placeholder={
            formElement.config.elementConfig.placeholder ||
            formElement.config.elementConfig.label
          }
          value={formElement.config.value}
          onChange={event => changed(event, formElement.id)}
          minRows={3}
          onBlur={onBlur}
        />
      );
      break;
		case "wysiwyg":
      inputElement = (
        <CustomTinyMceEditor 
          value={formElement.config.value}
          onChange={( value ) => changed({ target: { value }}, formElement.id)}
          onBlur={onBlur}
        />);
        // <ReactQuill
        //   id={formElement.id}
        //   placeholder={
        //     formElement.config.elementConfig.placeholder ||
        //     formElement.config.elementConfig.label
        //   }
        //   value={formElement.config.value}
        //   onChange={event => changed(event, formElement.id)}
        // />
        /*
        <CustomJoditEditor
          value={formElement.config.value}
          config={formElement.config.config}
          onChange={event => changed(event, formElement.id)}
          // placeholder={
          //   formElement.config.elementConfig.placeholder ||
          //   formElement.config.elementConfig.label
          // }
        />
        */
      
      break;
    case "checkbox":
    case "radio":
      inputElement = formElement.config.elementConfig.options.map(
        (option: any, i: number) => (
          <CustomInput
            key={formElement.id + i}
            type={formElement.config.elementType}
            id={formElement.id + i}
            name={formElement.id}
            label={option.displayValue}
            value={option.value}
            bsSize="lg"
            checked={
              formElement.config.value === option.value ||
              (typeof formElement.config.value === "object" &&
                formElement.config.value.indexOf(option.value) !== -1)
            }
            onChange={event => {
              changed(event, formElement.id)
              setTimeout(() => {
                if(onBlur) {
                  onBlur();
                }
              }, 1)
            }}
            invalid={!formElement.config.valid && formElement.config.touched}
            disabled={formElement.config.disabled}
          />
        )
      );
      inputElement = <div>{inputElement}</div>;
      break;
    case "color":
      inputElement = (
        <ColorPicker
          className={classes.Input}
          value={formElement.config.value}
          onChange={(color: string) => {
            changed(color, formElement.id);
            if(onBlur) {
              setTimeout(() => {
                //onBlur();
              }, 1)
            }
          }}
          style={formElement.config.style}
        />
      );
      break;
    case "select":
      inputElement = (
        <CustomSelect
          className={classes.Input}
          value={formElement.config.value}
          onChange={(value: string) => {
            if(formElement.config.onChange) {
              formElement.config.onChange(value);
            }
            changed({ target: {value}}, formElement.id)
            if(onBlur) {
              setTimeout(() => {
                //onBlur();
              }, 1)
            }
          }}
          options={formElement.config.options}
          isMulti={formElement.config.elementConfig.isMulti}
          valueMapper={formElement.config.valueMapper}
          placeholder={
            formElement.config.elementConfig.placeholder ||
            formElement.config.elementConfig.label
          }
          isClearable={formElement.config.elementConfig.isClearable}
          isDisabled={formElement.config.elementConfig.isDisabled}
        />
      );
      break;
    case "dropzone":
      inputElement = (
        <CustomDropzone
          className={classes.Input}
          accept={formElement.config.elementConfig.accept}
          text={formElement.config.elementConfig.text}
          dragText={formElement.config.elementConfig.dragText}
          contentType={formElement.config.elementConfig.contentType}
          onChange={(attachments: Array<IAttachment> | IAttachment) => {
            let filtered=attachments;
            if(Array.isArray(attachments)) {
              filtered=attachments.filter((a)=>a && a!=undefined);
            }
            changed({ target: { value: filtered}}, formElement.id);
            
            if(onBlur) {
              setTimeout(() => {
                onBlur();
              }, 1)
            }
            
          }}
          value={formElement.config.value}
          config={formElement.config.elementConfig.config}
          onDrop={formElement.config.elementConfig.onDrop}
        />
      );
      break;
    case "font":
      inputElement = (
        <div className={classes.FontPicker}>
          <FontPicker 
            apiKey="AIzaSyDJUA_p0L_Fep37wdfR8mJEKNi3p9YTcKI"
            activeFontFamily={formElement.config.value}
            limit={200}
            onChange={(nextFont) => {
              changed({ target: { value: nextFont.family}}, formElement.id)
              if(onBlur) {
                setTimeout(() => {
                  onBlur();
                }, 1)
              }
            }}
            pickerId={formElement.id}
          /> 
        </div>
      )
      break;
    case "datepicker":
      inputElement = (
        <ReactDatePicker
          className={['form-control', classes.Input].join(' ')}
          selected={formElement.config.value}
          onChange={date => {
            changed({ target: { value: date } }, formElement.id);
            if(onBlur) {
              setTimeout(() => {
                onBlur();
              }, 1)
            }
          }}
          dateFormat="dd.MM.yyyy"
          isClearable
          placeholderText={
            formElement.config.elementConfig.placeholder ||
            formElement.config.elementConfig.label
          }
        />
      );
      break;
    default:
      inputElement = (
        <Input
          autoComplete="new-password"
          className={classes.Input}
          type={formElement.config.elementConfig.type}
          value={formElement.config.value}
          placeholder={
            formElement.config.elementConfig.placeholder ||
            formElement.config.elementConfig.label
          }
          onChange={event => changed(event, formElement.id)}
          onBlur={onBlur}
          invalid={!formElement.config.valid && formElement.config.touched}
        />
      );
  }

  let label = null;
  if (formElement.config.elementConfig.label) {
    label = (
      <Label for={formElement.id} className={classes.Label}>
        {formElement.config.elementConfig.label}
      </Label>
    );
  }

  if (formElement.config.elementConfig.hasSubmit) {
    inputElement = (
      <div className={classes.groupWithSubmitInput}>
        {inputElement}
        <Button type="submit" color="primary">Send</Button>
      </div>
    )
  }

	let showLabel=(formElement.config.value!=="" || !formElement.config.elementConfig.hidden);

  let inputEl = (
    <FormGroup>
			{(showLabel || hideLabel)?label:""}
      {showLabel?inputElement:""}
    </FormGroup>
  );

  return inputEl;
};

export default input;
