import React, { CSSProperties } from "react";

import { EContentType } from "../../../../../../../enums";

import {
  IBlockContent,
	IUserTemplateBlockDraggable,
  IUserTemplateAreaBlock,

} from "../../../../../../../interfaces";


import BlockContentShow from "../../../../Blocks/Block/BlockContentShow";

import classes from "../OfferCombined.module.scss";

interface IProps {
		index: number,
		block: IUserTemplateAreaBlock,

    content: Array<IBlockContent>,
    styles: CSSProperties,
		setBlockContent: (item: IBlockContent)=>void,
		setBlockContentIsOpen: (item: boolean)=>void,

		setAreaBlock?: (item: IUserTemplateAreaBlock)=>void,
		setActiveBlock: (item:  IUserTemplateBlockDraggable)=>void,
		activeBlock: IUserTemplateBlockDraggable,

}

const RenderContent: React.FC<IProps> = ({
	content,
	styles,
	setBlockContent,
	setBlockContentIsOpen,
	index,
	block,
	setAreaBlock,
	setActiveBlock,
	activeBlock,
}) => {
	if (!content || !content.length) {
		if(block.blockType==="DYNAMIC") {
			return (<div
			className={classes.emptyContent}
			onClick={()=>{
				if(!activeBlock || (activeBlock.id!==block.id)) {
					setActiveBlock(block as IUserTemplateBlockDraggable)
				}
				else {
						setAreaBlock(block);
						setBlockContent(null);
					  setBlockContentIsOpen(true);
				}
			}}
			>
				This multi item element has no items!<br/>
				<small>Click to add items</small>

			</div>);
		}
		else {
			return (<div
			className={classes.emptyContent}
			onClick={()=>{
				if(!activeBlock || (activeBlock.id!==block.id)) {
					setActiveBlock(block as IUserTemplateBlockDraggable)
				}
			}}
			>
				This element has no content!<br/>
				<small>Select <b>Add new content type</b> on right</small>
			</div>);
		}
	}

		// Render Content Row
    return (
      <div
				className={classes.BlockContent}
				key={"bc"+index}
			>
        {content.map((blockContent,index) => {
          let contentStyles = { ...styles };
          if (blockContent.contentType === EContentType.PRICING) {
            contentStyles = {};
          }
          if (blockContent.contentType === EContentType.FIELDS) {
            contentStyles = {};
          }
					
					if (blockContent.contentType === EContentType.SCRIPT) {
              return <BlockContentShow
                blockContent={blockContent}
                showDummyContent={true}
                showOnlyInTemplateEditor={true}
              />
					}
					// Render single Content
          return (
            <div
							style={contentStyles}
							key={blockContent.id}
							className={classes.contentBorder}
							onClick={()=>{
								if(!activeBlock || (activeBlock.id!==block.id)) {
									setActiveBlock(block as IUserTemplateBlockDraggable);
								}
								else {
									if(block.blockType==="DYNAMIC") {
										setAreaBlock(block);
										setBlockContent(null);
							      setBlockContentIsOpen(true);
									}
									else {
										setAreaBlock(null);
										setBlockContent(blockContent);
							      setBlockContentIsOpen(true);
									}
								}
							}}
						>
              <BlockContentShow
                blockContent={blockContent}
                showDummyContent={true}
                showOnlyInTemplateEditor={true}
              />
            </div>
          );
        })}
      </div>
    );

};

export default RenderContent;
