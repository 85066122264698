import {
  faCode,
  faFont,
  faImage,
  faFileCsv,
  faCog,
  faTrashAlt,
  faFilePdf,
  faPaperclip,
  faSignature,
	faTable,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties } from "react";
import { EContentType } from "../../../enums/content-types";

import classes from "./ContentTypeIcon.module.scss";

interface IProps {
  active?: boolean;
  contentType: EContentType;
  className?: CSSProperties | string;
  onClick?: () => void;
}

const ContentTypeIcon: React.FC<IProps> = ({
  active,
  contentType,
  className,
  onClick
}) => {
  const containerClasses:[CSSProperties | string] = [classes.Container];

  if (active) {
    containerClasses.push(classes.Active);
  }

  if (className) {
    containerClasses.push(className);
  }

  let icon = null;
  if(contentType.startsWith("PRIVATE")) {
    icon = faCog;
  }
  else {
  switch (contentType) {
    case EContentType.CONTENT:
      icon = faFont;
      break;
    case EContentType.IMAGE:
      icon = faImage;
      break;
    case EContentType.VISUAL:
      icon = faImage;
      break;
		case EContentType.FIELDS:
			icon = faTable;
			break;
		case EContentType.PRODUCTS:
    case EContentType.PRODUCTS2:      
			icon = faTable;
			break;
    case EContentType.CUSTOM:
    case EContentType.PRICING:
    case EContentType.DEAL_BETWEEN:
    case EContentType.TEAM_MEMBER:
    case EContentType.LEGAL_BOX:  
    case EContentType.HTML:
    case EContentType.TAG:
    case EContentType.PARTIES:
      icon = faCode;
      break;
    case EContentType.CSV:
      icon = faFileCsv;
      break;
    case EContentType.DYNAMIC:
    case EContentType.SCRIPT:
    //case EContentType.PRIVATE:      
      icon = faCog;
      break;
    case EContentType.DELETE:
      icon = faTrashAlt;
      containerClasses.push(classes.Red);
      containerClasses.push(classes.Clickable);
      break;
    case EContentType.PDF:
      icon = faFilePdf;
      break;
    case EContentType.ATTACHMENT:
      icon = faPaperclip;
      break;
    case EContentType.SIGNATURE:
      icon = faSignature;
      break;
    case EContentType.SIGNATURES:
      icon = faSignature;
      break;

    default:      
  }
  }

  return (
    <div className={containerClasses.join(" ")} onClick={onClick}>
      <FontAwesomeIcon icon={icon} size="xs" />
    </div>
  );
};

export default ContentTypeIcon;
