import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect
} from "react";
import { EContentType, EDropzoneType } from "../../../../../../../enums";
import {
  IBlockContent,
  IBlockContentScript
} from "../../../../../../../interfaces";
import {
  controlsToFormGroups,
  defaultInputChangedHandler,
  getFormData
} from "../../../../../../../shared/utility";
import { IBlockContentEditItem } from "../../BlockContentEdit";

interface IProps {
  ref: any;
  blockContent: IBlockContent;
  onChange?: (blockContent: IBlockContent) => void;
}

const BlockContentScriptEdit: React.ForwardRefExoticComponent<IBlockContentEditItem> = forwardRef(
  (props, ref) => {
    const [init, setInit] = useState(false);
    const blockContent = props.blockContent as IBlockContentScript;

    const [controlsState, setControlsState] = useState({

      controls: {
        backgroundcolor: {
          elementType:"color",
          elementConfig: {
            label: "Background Color",
            placeholder: blockContent.name,
            type: "color"
          },
          validation: {
            required: false
          },
          valid: true,
          touched: false,
          value: blockContent.backgroundcolor || '',
          style: {"bottom":"unset"},
        },
        titlecolor: {
          elementType:"color",
          elementConfig: {
            label: "Title Color",
            placeholder: blockContent.name,
            type: "color"
          },
          validation: {
            required: false
          },
          valid: true,
          touched: false,
          value: blockContent.titlecolor || '',
          style: {"bottom":"unset"},
        }

      },
      formIsValid: false

    });

    const getData = () => {
      return Object.assign(blockContent, getFormData(controlsState.controls));
    };

    useEffect(() => {
      if (props.onChange && init) {
        props.onChange(getData());
      }
      // eslint-disable-next-line
    }, [controlsState.controls]);

    useEffect(() => {
      setInit(true);
    }, []);

    useImperativeHandle(ref, () => ({
      getData
    }));

    const inputChangedHandler = (
      event: React.ChangeEvent<HTMLInputElement>,
      controlName: string
    ) => {
      defaultInputChangedHandler(
        event,
        controlName,
        controlsState,
        setControlsState
      );
    };

    let blurHandler:any = null;

    const inputs = controlsToFormGroups(controlsState.controls, inputChangedHandler, blurHandler,true);

    return (<div>
        This element will replace background color, title color and h1,h2,h3,h4,h5 colors in whole document.
        {inputs}
      </div>);
  }
);

export default BlockContentScriptEdit;
