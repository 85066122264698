import React, { forwardRef } from 'react';

import { EContentType } from '../../../../../enums';
import { IBlockContent } from '../../../../../interfaces';
import BlockContentContentEdit from './BlockContent/BlockContentContent/BlockContentContentEdit';
import BlockContentImageEdit from './BlockContent/BlockContentImage/BlockContentImageEdit';
import BlockContentPricingTableEdit from './BlockContent/BlockContentPricingTable/BlockContentPricingTableEdit';
import BlockContentVisualEdit from './BlockContent/BlockContentVisual/BlockContentVisualEdit';
import BlockContentTeamMemberEdit from './BlockContent/BlockContentTeamMember/BlockContentTeamMemberEdit';
import BlockContentAttachmentEdit from './BlockContent/BlockContentAttachment/BlockContentAttachmentEdit';

import BlockContentFieldsEdit from "./BlockContent/BlockContentFields/BlockContentFieldsEdit";

import BlockContentProductsEdit from "./BlockContent/BlockContentProducts/BlockContentProductsEdit";
import BlockContentProducts2Edit from "./BlockContent/BlockContentProducts2/BlockContentProducts2Edit";
import BlockContentProducts3Edit from "./BlockContent/Private/BlockContentProducts3/BlockContentProducts3Edit";

import BlockContentSignaturesEdit from "./BlockContent/BlockContentSignatures/BlockContentSignaturesEdit";

import BlockContentGoogleSlidesEdit from "./BlockContent/BlockContentGoogleSlides/BlockContentGoogleSlidesEdit";

import BlockContentHTMLEdit from "./BlockContent/BlockContentHTML/BlockContentHTMLEdit";

import BlockContentPartiesEdit from "./BlockContent/BlockContentParties/BlockContentPartiesEdit";

import BlockContentScriptEdit from "./BlockContent/BlockContentScript/BlockContentScriptEdit";

interface IProps {
  ref: any;
  blockContent: IBlockContent;
  onChange?: (blockContent: IBlockContent) => void;
  onBlur?: (blockContent: IBlockContent) => void;
}

export interface IBlockContentEditItem {
  ref: any;
  blockContent: IBlockContent;
  onChange?: (blockContent: IBlockContent) => void;
  onBlur?: (blockContent: IBlockContent) => void;
}

const BlockContentEdit: React.ForwardRefExoticComponent<IProps> = forwardRef(
  ({ blockContent, onChange, onBlur }, ref) => {
    switch (blockContent.contentType) {
      case EContentType.CONTENT:
        return <BlockContentContentEdit ref={ref} blockContent={blockContent} onChange={onChange} onBlur={onBlur} />;
      case EContentType.VISUAL:
        return <BlockContentVisualEdit ref={ref} blockContent={blockContent} onChange={onChange} onBlur={onBlur} />;
      case EContentType.IMAGE:
        return <BlockContentImageEdit ref={ref} blockContent={blockContent} onChange={onChange} onBlur={onBlur}/>;
      case EContentType.PRICING:
        return <BlockContentPricingTableEdit ref={ref} blockContent={blockContent} onChange={onChange} onBlur={onBlur} />;
      case EContentType.TEAM_MEMBER:
        return <BlockContentTeamMemberEdit ref={ref} blockContent={blockContent} onChange={onChange} onBlur={onBlur} />;
      case EContentType.ATTACHMENT:
        return <BlockContentAttachmentEdit ref={ref} blockContent={blockContent} onChange={onChange} onBlur={onBlur} />;

	    case EContentType.FIELDS:
  	    return <BlockContentFieldsEdit ref={ref} blockContent={blockContent}  onChange={onChange} onBlur={onBlur} />;
	    case EContentType.PRODUCTS:
  	    return <BlockContentProductsEdit ref={ref} blockContent={blockContent}  onChange={onChange} onBlur={onBlur} />;

      case EContentType.PRIVATE:
        return <BlockContentProducts2Edit ref={ref} blockContent={blockContent}  onChange={onChange} onBlur={onBlur} />;
      case EContentType.PRIVATE2:
        return <BlockContentProducts3Edit ref={ref} blockContent={blockContent}  onChange={onChange} onBlur={onBlur} />;


      case EContentType.SIGNATURES:
        return <BlockContentSignaturesEdit ref={ref} blockContent={blockContent}  onChange={onChange} onBlur={onBlur} />;

      case EContentType.GOOGLESLIDES:
        return <BlockContentGoogleSlidesEdit ref={ref} blockContent={blockContent}  onChange={onChange} onBlur={onBlur} />;

      case EContentType.PARTIES:
        return <BlockContentPartiesEdit ref={ref} blockContent={blockContent}  onChange={onChange} onBlur={onBlur} />;

//      case EContentType.HTML:
//        return <BlockContentHTMLEdit ref={ref} blockContent={blockContent}  onChange={onChange} onBlur={onBlur} />;

      case EContentType.SCRIPT:
        return <BlockContentScriptEdit ref={ref} blockContent={blockContent} onChange={onChange} onBlur={onBlur}/>;


      default:
        return <div>
						This content type has no customisable fields.
					</div>;
    }
  }
);

export default BlockContentEdit;
